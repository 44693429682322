// ** Redux Imports

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import axios from 'axios'
import { downloadCSV } from '@exports'
import moment from 'moment'

// ** To Exports Data


// ** Axios Imports


export const progressEvent = createAsyncThunk('news/progressEvent', values => {
  return values || 0
})
export const getData = createAsyncThunk('news/getData', async params => {
  const response = await axios.get('/api/private/berita', { params })
  return {
    params,
    data: response.data.data,
    totalPages: response.data.paging.totalItems
  }
})

export const getDetailNews = createAsyncThunk('news/getDetailNews', async id => {
  const response = await axios.get(`/api/private/berita/${id}`)
  return response.data.data
})

export const createNews2 = createAsyncThunk('news/createNews2', async (payload, { dispatch, getState }) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress: (e) => {
      const { loaded, total } = e
      const percent = Math.floor((loaded * 100) / total)
      console.log('onUploadProgress', `${loaded}kb of ${total}kb | ${percent}%`)
      if (percent <= 100) {
        dispatch(progressEvent(percent))
        // dispatch({
        //   type: 'UPLOAD_PROGRESS',
        //   data: percent
        // })
      }
    }
  }
  const response = await axios.post(`/api/v2/private/berita`, payload, config)
  // await dispatch(getData(getState().news.params))
  return response.data
})

export const updateNews = createAsyncThunk('news/updateNews', ({ id, body }, { dispatch, getState }) => {
  const config = {
    headers: {
      Accept: 'application/json'
      // 'Content-Type': 'multipart/form-data'
    },
    onUploadProgress: (e) => {
      const { loaded, total } = e
      const percent = Math.floor((loaded * 100) / total)
      console.log('onUploadProgress', `${loaded}kb of ${total}kb | ${percent}%`)
      dispatch(progressEvent(percent))
      if (percent <= 100) {
        // dispatch({
        //   type: 'UPLOAD_PROGRESS',
        //   data: percent
        // })
      }
    }
  }
  return axios.put(`/api/v2/private/berita/${id}`, body, config).then((res) => {
    // toast.success(res?.data.alert.message)
    // dispatch(getData(getState().booking.params))
    return res?.data
  })
  .catch((err) => {
    return err
  })
})

export const duplicateNews = createAsyncThunk('news/duplicateNews', async (id, { dispatch, getState }) => {
  const response = await axios.post(`/api/private/berita/duplicate/${id}`)
  await dispatch(getData(getState().news.params))
  return response.data
})

export const deleteNews = createAsyncThunk('news/deleteTask', async (id, { dispatch, getState }) => {
  const response = await axios.delete(`/api/private/berita/${id}`)
  await dispatch(getData(getState().news.params))
  return response.data
})

export const unAssignMe = createAsyncThunk('news/unAssignMe', async (id, { dispatch, getState }) => {
  const response = await axios.patch(`/api/private/berita/${id}/un-assign`)
  // await dispatch(getDetailNews(id))
  window.location = '/berita/list'
  return response.data
})

export const getReports = createAsyncThunk('news/deleteTask', async params => {
  const response = await axios.get(`/api/private/reports/berita`, { params })
  const {start_date, end_date} = params
  downloadCSV(`Berita-${moment(start_date).format("YMMDD")}-${moment(end_date).format("YMMDD")}`,
    response.data.data.map((row) => {
      return { 
        JUDUL_BERITA: row.judul, 
        NAMA_KATEGORI: row.nama_kategori,
        NAMA_DAERAH: row.nama_daerah,
        AUTHOR_NAME: row.author_name,
        EDITOR_NAME: row.editor_name,
        PUBLISH_DATE: moment(row.publishedAt).format("DD-MM-YYYY hh:mm"),
        URL: row.url
      }
    })
  )
  return response.data
})

// ** Upload Globals
export const uploadImage = createAsyncThunk('news/uploadImage', async (payload, { dispatch, getState }) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  }
  const response = await axios.post(`/api/private/upload/image`, payload, config)
  return response?.data || {}
})


export const newsSlice = createSlice({
  name: 'news',
  initialState: {
    data: [],
    selectedNews: {},
    progress: 0
    // params: {
    //   filter: '',
    //   search: '',
    //   sort: '',
    //   tag: ''
    // }
  },
  // reducers: {
  //   reOrderTasks: (state, action) => {
  //     state.data = action.payload
  //   },
  //   selectedNews: (state, action) => {
  //     state.selectedNews = action.payload
  //   }
  // },
  extraReducers: builder => {
    builder
    .addCase(getData.fulfilled, (state, action) => {
      state.progress = 0
      state.data = action.payload.data
      state.params = action.payload.params
      state.total = action.payload.totalPages
    })
    .addCase(getDetailNews.fulfilled, (state, action) => {
      state.progress = 0
      state.selectedNews = action.payload
    }).addCase(progressEvent.fulfilled, (state, action) => {
      state.progress = action.payload
    })

  }
})

// export const { reOrderTasks, selectedNews } = newsSlice.actions

export default newsSlice.reducer
