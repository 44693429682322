// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// ** To Exports Data
import { downloadCSV } from '@exports'
import moment from 'moment'

// ** Axios Imports
import axios from 'axios'
// Toast
import ToastComponent from '@components/toast'
import { toast } from 'react-toastify'


export const progressEvent = createAsyncThunk('indepth/progressEvent', values => {
  return values
})

// ** get all
export const getData = createAsyncThunk('indepth/getData', async params => {
  const response = await axios.get('/api/private/indepth', { params })
  return {
    params,
    data: response.data.data,
    totalPages: response.data.paging.totalItems
  }
})

// ** get detail
export const getDetailIndepth = createAsyncThunk('indepth/getDetailIndepth', async id => {
  const response = await axios.get(`/api/private/indepth/${id}`)
  return response.data.data
})

// ** created
export const createIndepth = createAsyncThunk('indepth/createIndepth', (payload, { dispatch, getState }) => {
  const config = {
    headers: {
      Accept: 'application/json'
      // 'Content-Type': 'multipart/form-data'
    },
    onUploadProgress: (e) => {
      const { loaded, total } = e
      const percent = Math.floor((loaded * 100) / total)
      // console.log('onUploadProgress', `${loaded}kb of ${total}kb | ${percent}%`)
      if (percent <= 100) {
        dispatch(progressEvent({type: '', percent}))
      }
    }
  }
  return axios.post(`/api/v2/private/indepth`, payload, config).then(res => {
    dispatch(getData(getState().indepth.params))
    return res?.data
  }).catch(err => {
    const {alert} = {...err.response.data}
    toast.success(<ToastComponent
        code={alert?.code}
        message={alert?.message}
        color='danger'
      />, { autoClose: 5000, hideProgressBar: true, closeButton: true})

    return err.response.data
  })
})

// ** updated
export const updateIndepth = createAsyncThunk('indepth/updateIndepth', ({ id, body }, { dispatch, getState }) => {
  const config = {
    headers: {
      Accept: 'application/json' //,'Content-Type': 'multipart/form-data'
    },
    onUploadProgress: (e) => {
      const { loaded, total } = e
      const percent = Math.floor((loaded * 100) / total)
      // console.log('onUploadProgress', `${loaded}kb of ${total}kb | ${percent}%`)
      dispatch(progressEvent({type: '', percent}))
      if (percent <= 100) {
      }
    }
  }

  return axios.put(`/api/v2/private/indepth/${id}`, body, config).then((res) => {
    // toast.success(res?.data.alert.message)
    dispatch(getData(getState().indepth.params))
    return res?.data
  })
  .catch((err) => {
    return err
  })
})

// ** destroy
export const destroyIndepth = createAsyncThunk('indepth/destroyIndepth', async (id, { dispatch, getState }) => {
  const response = await axios.patch(`/api/private/indepth/${id}`)
  await dispatch(getData(getState().indepth.params))
  return response.data
})

// ** exports
export const getReports = createAsyncThunk('indepth/getReports', async params => {
  const response = await axios.get(`/api/private/indepth`, { params })
  const {start_date, end_date} = params
  downloadCSV(`Indepth-${moment(start_date).format("YMMDD")}-${moment(end_date).format("YMMDD")}`,
    response.data.data.map((row) => {
      return { 
        judul: row.judul, 
        // nama_kategori: row.nama_kategori,
        nama_daerah: row.nama_daerah,
        author_name: row.author_name,
        editor_name: row.editor_name,
        publish_date: moment(row.publishedAt).format("DD-MM-YYYY hh:mm"),
        url: `https://www.rri.co.id/indepth${row.id_daerah === 1 ? '' : `/${row.slug_daerah}`}/${row.id}/${row.slug}`
      }
    })
  )
  return response.data
})

export const _ = createSlice({
  name: 'indepth',
  initialState: {
    data: [],
    selected: {},
    progress: 0,
    params: {},
    total: 0
  },
  // reducers: {
  //   reOrderTasks: (state, action) => {
  //     state.data = action.payload
  //   },
  //   selectedNews: (state, action) => {
  //     state.selectedNews = action.payload
  //   }
  // },
  extraReducers: builder => {
    builder
    .addCase(createIndepth.fulfilled, (state, action) => {
      state.progress = 0
    }).addCase(updateIndepth.fulfilled, (state, action) => {
      state.progress = 0
    }).addCase(getData.fulfilled, (state, action) => {
      state.progress = 0
      state.data = action.payload.data
      state.params = action.payload.params
      state.total = action.payload.totalPages
    })
    .addCase(getDetailIndepth.fulfilled, (state, action) => {
      state.progress = 0
      state.selected = action.payload
    }).addCase(progressEvent.fulfilled, (state, action) => {
      const {type, percent} = action.payload
      if (type === 'audio') {
        state.progressAudio = percent
      } else if (type === 'image') {
        state.progressImage = percent
      } else if (type === 'video') {
        state.progressVideo = percent
      } else {
        state.progress = percent
      }
      
    })

  }
})

// export const { reOrderTasks, selectedNews } = _.actions

export default _.reducer
