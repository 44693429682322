// ** Initial State
const initialState = {
  data: [],
  listMenu: [],
  total: 1,
  params: {},
  selectedMenu: {},
  alert: {},
  listDaerah: []
}

const menu = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADING':
      return { ...state, alertSave: {}, loading: true }
    
    case 'GET_DATA':
      return {
        ...state,
        listMenu: action.data,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_DETAIL':
      return { 
        ...state, 
        selectedMenu: action.selected 
      }
    
    default:
      return { 
        ...state, 
        alert: action.alert  
      }
  }
}
export default menu
